import { useState, useEffect } from 'react';

/**
 * @typedef Breakpoint
 * @property {boolean} active
 * @property {string} key
 * @property {number} maxWidth
 * @property {number} minWidth
 */

/**
 * @typedef BreakpointsConstants
 * @property {string} EXTRA_SMALL
 * @property {string} SMALL
 * @property {string} MEDIUM
 * @property {string} LARGE
 * @property {string} EXTRA_LARGE
 * @property {string} EXTRA_EXTRA_LARGE
 */

/**
 * @typedef UseMediaQueryReturnValue
 * @property {BreakpointsConstants} BREAKPOINTS
 * @property {function} isCurrentBreakpoint
 * @property {function} isGreaterOrEqualTo
 * @property {function} isGreaterThan
 * @property {function} isLowerOrEqualTo
 * @property {function} isLowerThan
 */

/** @type {Breakpoint[]} */
const initialBreakpointsDefinition = [
  { key: 'EXTRA_SMALL', minWidth: 0 },
  { key: 'SMALL', minWidth: 576 },
  { key: 'MEDIUM', minWidth: 768 },
  { key: 'LARGE', minWidth: 984 },
  { key: 'EXTRA_LARGE', minWidth: 1200 },
  { key: 'EXTRA_EXTRA_LARGE', minWidth: 1560 },
];

/**
 * React Hook to track changes in the viewport's size
 * based on our breakpoints
 *
 * @function useMediaQuery
 * @returns {UseMediaQueryReturnValue}
 */
export default function useMediaQuery(isDesktopSSR = null) {
  /** @type {Object<string, Breakpoint>} */
  const breakpoints = initialBreakpointsDefinition.reduce((output, breakpoint, index) => {
    const bp = {
      ...output,
      [breakpoint.key]: {
        maxWidth:
          index < initialBreakpointsDefinition.length - 1 ? initialBreakpointsDefinition[index + 1].minWidth - 1 : 9999,
        minWidth: breakpoint.minWidth,
      },
    };
    return bp;
  }, {});

  const getCurrentBreakpoint = () =>
    Object.keys(breakpoints).find((breakpointKey) => {
      const breakpoint = breakpoints[breakpointKey];
      const maxWidthQuery = breakpoint.maxWidth ? ` and (max-width: ${breakpoint.maxWidth}px)` : '';
      return window.matchMedia(`(min-width: ${breakpoint.minWidth}px)${maxWidthQuery}`).matches;
    });
  const initialBreakpoint = isDesktopSSR ? 'EXTRA_LARGE' : 'EXTRA_SMALL';
  const [activeBreakpoint, setActiveBreakpoint] = useState(initialBreakpoint);

  const handleMQ = (breakpoint, mediaQuery) => (e) => {
    if (mediaQuery.matches) {
      setActiveBreakpoint(breakpoint);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setActiveBreakpoint(getCurrentBreakpoint());
      const mqs = Object.keys(breakpoints).map((breakpointKey) => {
        const breakpoint = breakpoints[breakpointKey];
        const maxWidthQuery = breakpoint.maxWidth ? ` and (max-width: ${breakpoint.maxWidth}px)` : '';
        const mq = window.matchMedia(`(min-width: ${breakpoint.minWidth}px)${maxWidthQuery}`);
        const handler = handleMQ(breakpointKey, mq);
        if ('addListener' in mq) {
          mq.addListener(handler);
        } else {
          mq.addEventListener('change', handler);
        }

        return () => {
          if ('removeListener' in mq) {
            mq.removeListener(handler);
          } else {
            mq.removeEventListener('change', handler);
          }
        };
      });

      return () => {
        mqs.forEach((unsubscribeFn) => unsubscribeFn());
      };
    }

    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @function isCurrentBreakpoint
   * @param {string} bp Breakpoint being checked as matching the current viewport
   * @returns {boolean}
   */
  function isCurrentBreakpoint(bp) {
    return bp === activeBreakpoint;
  }

  /**
   * @function isGreaterThan
   * @param {string} bp Breakpoint being checked as being lower than the current viewport
   * @returns {boolean}
   */
  function isGreaterThan(bp) {
    return breakpoints[activeBreakpoint].minWidth > breakpoints[bp].maxWidth;
  }

  /**
   * @function isGreaterOrEqualTo
   * @param {string} bp Breakpoint being checked as if it matches or is lower than the current viewport
   * @returns {boolean}
   */
  function isGreaterOrEqualTo(bp) {
    return isCurrentBreakpoint(bp) || isGreaterThan(bp);
  }

  /**
   * @function isLowerOrEqualTo
   * @param {string} bp Breakpoint being checked as if it matches or is greater than the current viewport
   * @returns {boolean}
   */
  function isLowerOrEqualTo(bp) {
    return isCurrentBreakpoint(bp) || isLowerThan(bp);
  }

  /**
   * @function isGreaterOrEqualTo
   * @param {string} bp Breakpoint being checked if is greater than the current viewport
   * @returns {boolean}
   */
  function isLowerThan(bp) {
    return !isGreaterOrEqualTo(bp);
  }

  return {
    /** @type {BreakpointsConstants} */
    BREAKPOINTS: Object.keys(breakpoints).reduce((output, bp) => ({ ...output, [bp]: bp }), {}),

    isCurrentBreakpoint,
    isGreaterOrEqualTo,
    isGreaterThan,
    isLowerOrEqualTo,
    isLowerThan,
  };
}
